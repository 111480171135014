@font-face {
  font-display: swap;
  font-family: 'Fira Code';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/FiraCode-Regular.woff") format("woff"); 
}

@font-face {
  font-display: swap;
  font-family: 'Topaz';
  font-style:  normal;
  font-weight: 800;
  src: url("../fonts/FiraCode-Regular.woff") format("woff"); 
}

@font-face {
  font-display: swap;
  font-family: 'Topaz';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/topaz.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: 'Topaz';
  font-style:  normal;
  font-weight: 800;
  src: url("../fonts/topaz.ttf") format("truetype");
}
